import React from 'react';
import Feature from '../../components/feature/Feature';
import './sdg.css';

const sdg = () => (
  <div className="gpt3__whatgpt3 section__margin" id="sdg">
    <div className="gpt3__whatgpt3-feature">
      <Feature
        title="UN SDG's"
        text="WestLight Solar is dedicated to advancing the United Nations Sustainable Development Goals (UN SDGs), with a strong focus on:"
      />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Our Commitment to the UN Sustainable Development Goals;</h1>
      {/* <p>...what needs to happen!</p> */}
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature
        title="SDG 1"
        text="No Poverty – Contributing to the eradication of extreme poverty by improving energy access, reducing household energy costs, and fostering economic opportunities in underserved communities."
      />
      <Feature
        title="SDG 7"
        text="Affordable and Clean Energy – Ensuring that vulnerable populations gain access to reliable, sustainable, and modern energy solutions, driving both social and economic transformation."
      />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <p>By aligning with these global goals, WestLight Solar is actively working toward a future where clean energy empowers communities and breaks the cycle of poverty.</p>
      {/* <p>...what needs to happen!</p> */}
    </div>
  </div>
);

export default sdg;
