import React from 'react';
import gpt3Logo from '../../assets/logo3.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Do you want to make a change with us?</h1>
    </div>

    <div className="gpt3__footer-btn">
      {/* <p>Request Early Access</p> */}
      <a target="_blank" rel="noopener noreferrer" href="https://mchanga.africa/fundraiser/64039">
        <p>Donate</p>
      </a>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>WestLight Solar CBO </p>
        <br />
        <p>P.O. Box 158</p>
        <br />
        <p>Vihiga 50310, Kenya</p>
      </div>
      {/* <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Overons</p>
        <p>Social Media</p>
        <p>Counters</p>
        <p>Contact</p>
      </div> */}
      {/* <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div> */}
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>Phones: +254 700 296939 / +254 717 287912 / +254 751 491089</p>
        <p>Email: info@westlightsolarkenya.com</p>
        <p>Website: www.westlightsolarkenya.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023-2025 WestLight Solar CBO. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
