import React from 'react';
import possibilityImage from '../../assets/IMG_20220908_201158_HDR.jpg';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>What we see ourselves achieving</h4>
      <h1 className="gradient__text">The possibilities are <br /> beyond imagination in alleviating poverty</h1>
      <p>By providing solar powered lanterns with a phone charging component, the WestLight Solar Community Project is working on a goal to cushion at least 67,000 households from their energy poverty, saving them 60 percent of their meagre incomes that are less than $2 a day.</p>
      <h4>Alleviate poverty.</h4>
    </div>
  </div>
);

export default Possibility;
