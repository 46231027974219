import React from 'react';
import './walkathon2024.css';
import walkImage1 from '../../assets/CommunitySolarWalkathonParticipants.JPG';
import walkImage2 from '../../assets/SchoolandVocationalTrainingCollegeSolarWalkathonParticipants.jpeg';
import walkImage3 from '../../assets/SchoolSolarWalkathonParticiapants.jpg';

const Walkathon2024 = () => (
  <div>
    <div className="gpt3__possibility section__padding" id="possibility">
      <div className="gpt3__possibility-image">
        <img src={walkImage1} alt="possibility" />
      </div>
      <div className="gpt3__possibility-content">
        {/* <h4>What we see ourselves achieving</h4> */}
        <h1 className="gradient__text">Solar Walkathon Event 2024</h1>
        <p>In May 2024, WestLight Solar CBO successfully hosted the Solar Walkathon, a powerful initiative that raised awareness about the benefits of solar energy while mobilizing funds to provide solar lanterns and clean energy cooking stoves to 14,000 vulnerable community members in Western Kenya.</p>
        <p>This life-changing effort impacted children, women, and persons living with disabilities (PLWD), enhancing respiratory and maternal health, reducing energy costs, and contributing to climate change mitigation. Through this event, WestLight Solar took a significant step toward a cleaner, healthier, and more sustainable future for rural communities that are not connected to the electricity grid.</p>
        <h4>We are thankful for all our individual and corporate donors that included MCCI – the Things Network, TotalEnergies Kenya, SunFunder, CAIPA Foundation and Drop Access.</h4>
      </div>
      {/* <div className="gpt3__blog-container">
        <div className="gpt3__blog-container_groupB">
          <Article imgUrl={blog01} date="May, 2023" text="Improving Maternal Health." />
          <Article imgUrl={blog02} date="Oct, 2022" text="Charles and his solar light" />
        </div>
      </div> */}
    </div>
    <div className="gpt3__possibility section__padding" id="possibility">
      <div className="gpt3__possibility-image">
        <img src={walkImage2} alt="possibility" />
      </div>
      <div className="gpt3__possibility-image">
        <img src={walkImage3} alt="possibility" />
      </div>
    </div>
  </div>
);

export default Walkathon2024;
