/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slider from 'react-slick';
import { MCCILogo, TotalEnergieslogo, TheThingsLogo, SunFunderLogo, ManhattanYouthlogo, DropAccesslogo, CAIPALOGOPDF, CountyGovermentofVihigaLogo } from './imports';
import './brand.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 6000,
  autoplaySpeed: 2000,
  cssEase: 'linear',
};

const Brand = () => (
  <div className="gpt3__brand section__padding" id="partners">
    <h2>Our Partners and Donors</h2>
    <br />
    <Slider className="Slider" {...settings}>
      <div>
        <img src={MCCILogo} />
        <p><strong>MCCI</strong></p>
      </div>
      <div>
        <img src={TheThingsLogo} />
        <p><strong>The Things Network</strong></p>
      </div>
      <div>
        <img src={TotalEnergieslogo} />
        <p><strong>TotalEnergies Kenya</strong></p>
      </div>
      <div>
        <img src={CAIPALOGOPDF} />
        <p><strong>CAIPA Foundation</strong></p>
      </div>
      <div>
        <img src={SunFunderLogo} />
        <p><strong>SunFunder, Kenya</strong></p>
      </div>
      <div>
        <img src={CountyGovermentofVihigaLogo} />
        <p><strong>County Government of Vihiga</strong></p>
      </div>
      <div>
        <img src={DropAccesslogo} />
        <p><strong>Drop Access, Kenya</strong></p>
      </div>
      <div>
        <img src={ManhattanYouthlogo} />
        <p><strong>Manhattan Youth</strong></p>
      </div>
    </Slider>
  </div>
);

export default Brand;
