import React from 'react';
import eventmage1 from '../../assets/SolarFairpitchimage.jpg';
import './event2025.css';

const Event2025 = () => (
  <div>
    <div className="gpt3__possibility section__padding" id="possibility">
      <div className="gpt3__possibility-image">
        <img src={eventmage1} alt="possibility" />
      </div>
      <div className="gpt3__possibility-content">
        {/* <h4>What we see ourselves achieving</h4> */}
        <h1 className="gradient__text">Westlight Solar Kenya 2025 Event</h1>
        <h4>Empowering the Future with Solar Innovation!</h4>
        <p>Join us as we ignite curiosity and innovation in renewable energy!</p>
        <p>This exciting event will:</p>
        <ul>
          <li>Give high school and vocational students hands-on experience with solar technologies, helping them understand and apply their benefits.</li>
          <li>Showcase groundbreaking, homegrown solar innovations designed to tackle local clean energy challenges.</li>
          <li>Connect industry experts with the community, presenting practical and affordable solar solutions available in the market.</li>
        </ul>
        <p>Be part of the movement shaping a brighter, cleaner future!</p>
        <p><strong>Thank You for Powering a Brighter Future!</strong></p>
        <p>We extend our deepest gratitude to all our donors and partners who have supported the WestLight Solar Project. Your generosity and commitment have helped bring clean, sustainable energy to communities in need, transforming lives and creating a more sustainable future.</p>
        <p>Thanks to your support, we have been able to provide reliable solar power lanterns to vulnerable members of the community in Western Kenya and consequently improving education, health, and improving livelihoods. Your contributions are not just about solar lighting—they are about empowerment, resilience, and hope.</p>
        <p>Together, we are making a lasting impact. Thank you for believing in our mission and for being a part of this journey toward a brighter, greener world.</p>
        <h4>#SolarFair2025 #CleanEnergy #Innovation</h4>
      </div>
    </div>
  </div>
);

export default Event2025;
