import React from 'react';

import { Footer, Blog, Possibility, Features, WhatGPT3, Header, SDG, Gifts, Walkathon2024, Event2025 } from './containers';
import { CTA, Brand, Navbar } from './components';

import './App.css';

const App = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    <Brand />
    <Walkathon2024 />
    <WhatGPT3 />
    <Features />
    <Possibility />
    <SDG />
    <Event2025 />
    <Gifts />
    <CTA />
    <Blog />
    <Footer />
  </div>
);

export default App;
